import React from 'react';
export default ({ itinerary }) => {
    if (itinerary) {
        const itineraryItems = itinerary
          .split("\n")
          .map((item, index) => {
            const trimmedItem = item.trim();
            // Remove any leading number followed by a dot and space (e.g., "1. ", "2. ")
            const cleanedItem = trimmedItem.replace(/^\d+\.\s*/, "");
            return <li key={index} className='f-16 pb-2'>{cleanedItem}</li>;
          })
          .filter(item => item); // Filter out any empty items

                  // If itinerary does not contain any newline characters, return as a single item
        if (itineraryItems.length === 0) {
          return (
              <ol>
                  <li>{itinerary.trim()}</li>
              </ol>
          );
      }
    
        return <ol>{itineraryItems}</ol>;
      } else {
        return <p>No itinerary available.</p>;
      }
};    
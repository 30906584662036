import React, { useState, useEffect, useCallback } from 'react';
import { Spinner, Alert } from '@themesberg/react-bootstrap';
import axios from 'axios';
import ViolationCard from './ViolationCard';
import { RotatingLines } from 'react-loader-spinner';


export default ({ data }) => {
    const [violations, setViolations] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchViolations = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/violations?assignedDriver=${data.id}`
            );

            setViolations(response.data.data);
        } catch (error) {
            setError('Error fetching violations');
        } finally {
            setLoading(false);
        }
    }, [data.id]);

    useEffect(() => {
        fetchViolations();
    }, [data.id]);

    const handleSuccess = (message) => {
        setError(null);
        fetchViolations(); // Reload the violations data after success
    };

    const handleError = (message) => {
        setError(message);
    };

    if (loading) {
        return <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
                      <RotatingLines
            visible={true}
            height="96"
            width="96"
            strokeColor='#262B40'
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
                        colors='#262B40'
                      />
                      <p className="mt-2 fw-semibold">Just a moment, processing your request.</p>
                    </div>;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <>            
            <div className="my-4">
                {violations.length === 0 ? <Alert variant="info">No violations record found.</Alert> :(
                    violations.map((item, index) => (
                        <ViolationCard violation={item} />
                    ))
                )}
            </div>
        </>
    );
};

import React, { useState, useEffect, useCallback } from 'react';
import { Spinner, Alert, Card } from '@themesberg/react-bootstrap';
import axios from 'axios';
import LeaveCard from './LeaveCard';  // Import the reusable LeaveCard component
import CreateLeavePlan from './CreateLeavePlan';
import LoaderComponent from '../../../components/LoaderComponent';

const getTimeDifferenceCategory = (startDate, endDate, leaveStatus) => {
    const today = new Date();
    const start = new Date(startDate);

    // Check for upcoming
    if (start >= today && leaveStatus === "approved") {
        return 'Upcoming';
    }

    // Otherwise, it's past
    return 'Past';
};

export default ({ data }) => {
    const [leaves, setLeaves] = useState({ upcoming: [], past: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchLeaves = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/leaves?driverId=${data.id}&role=driver`
            );

            const categorizedLeaves = { upcoming: [], past: [] };

            response.data.leavePlans.forEach(leave => {
                const category = getTimeDifferenceCategory(leave.leaveStartDate, leave.leaveEndDate, leave.leaveStatus);
                categorizedLeaves[category.toLowerCase()].push(leave);
            });

            setLeaves(categorizedLeaves);
        } catch (error) {
            setError('Error fetching leave data');
            console.error('Error fetching leaves:', error);
        } finally {
            setLoading(false);
        }
    }, [data.id]);

    useEffect(() => {
        fetchLeaves();
    }, [data.id]);

    const handleSuccess = (message) => {
        setError(null);
        fetchLeaves(); // Reload the leave data after success
    };

    const handleError = (message) => {
        setError(message);
    };

    if (loading) {
        return <LoaderComponent />;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <>
            <CreateLeavePlan data={data} reloadLeaves={fetchLeaves} /> {/* Pass reloadLeaves to CreateLeavePlan */}
            
            <div className="my-4">
                <h2 className="mb-3">Upcoming</h2>
                {leaves.upcoming.length === 0 ? (
                    <Card><Card.Body>No upcoming leaves found</Card.Body></Card>
                ) : (
                    leaves.upcoming.map((leave, index) => (
                        <LeaveCard
                            key={`upcoming-${index}`}
                            leave={{ ...leave, driverId: data.id }}
                            category="upcoming"
                            onSuccess={handleSuccess}  // Pass success handler to LeaveCard
                            onError={handleError}  // Pass error handler to LeaveCard
                        />
                    ))
                )}
            </div>

            <div className="mb-4">
                <h2 className="mb-3">Past</h2>
                {leaves.past.length === 0 ? (
                    <Alert variant="info">No past leaves</Alert>
                ) : (
                    leaves.past.map((leave, index) => (
                        <LeaveCard
                            key={`past-${index}`}
                            leave={{ ...leave, driverId: data.id }}
                            category="past"
                            onSuccess={handleSuccess}  // Pass success handler to LeaveCard
                            onError={handleError}  // Pass error handler to LeaveCard
                        />
                    ))
                )}
            </div>
        </>
    );
};

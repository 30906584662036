
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faChartArea, faChartBar, faChartLine, faFlagUsa, faFolderOpen, faGlobeEurope, faPaperclip, faUserPlus, faLocationArrow, faMapMarkerAlt, faAngleRight, faMapSigns, faDirections, faCompass, faEllipsisV, faSignOutAlt, faUsers, faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { faAngular, faBootstrap, faReact, faVuejs } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar, Modal, Form, Badge, Alert, InputGroup } from '@themesberg/react-bootstrap';
import { CircleChart, BarChart, SalesValueChart, SalesValueChartphone } from "./Charts";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ProfileCover from "../assets/img/profile-cover.jpg";
import moment from "moment-timezone";
import { Link, Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import ShortenAddress from "./Utility/ShortenAddress";
import { Routes } from "../routes";
import FormatTz from "./Utility/FormatTz";
import TripStatus from "./Utility/TripStatus";
import FormatTimeTz from "./Utility/FormatTimeTz";
import { RotatingLines } from 'react-loader-spinner';

export const ProfileCardWidget = (props) => {
  const { photo, userName, contactNo, email, birthDate, address } = props;
  const handleLogout = () => {

    localStorage.removeItem('token');
    setTimeout(() => {
      return <Redirect to="/sign-in" />; // Redirect to login page after logout
    }, 1000); // Simulating a 1 second delay
  };
  return (
    <Card border="light" className="text-center p-0 bg-transparent border-bottom border-0">
      <Card.Body className="pb-2">
        <Card.Img src={photo} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mb-4" />
        <Card.Title className="upperCase-keyword">{userName}</Card.Title>
        <Card.Subtitle className="fw-normal">{contactNo}</Card.Subtitle>
        <Card.Text className="text-gray">{email}</Card.Text>
      </Card.Body>
      <div className="d-block mb-2">
        <Button as={Link} size="xs" to={Routes.Signin.path} className="background-0 border-0" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
        </Button>
      </div>
    </Card>
  );
};

export const ChoosePhotoWidget = (props) => {
  const { title, photo } = props;

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">{title}</h5>
        <div className="d-xl-flex align-items-center">
          <div className="user-avatar xl-avatar">
            <Image fluid rounded src={photo} />
          </div>
          <div className="file-field">
            <div className="d-flex justify-content-xl-center ms-xl-3">
              <div className="d-flex">
                <span className="icon icon-md">
                  <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                </span>
                <input type="file" />
                <div className="d-md-block text-start">
                  <div className="fw-normal text-dark mb-1">Choose Image</div>
                  <div className="text-gray small">JPG, GIF or PNG. Max size of 800K</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const OngoingTripsWidget = (props) => {
  const { tourId, bookingId, driverId, title, pickUpPoint, pickUpDate, pickUpTime, dropPoint, onTripEnded, paymentStatus, fare, mapUrl } = props;
  const [showModal, setShowModal] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(fare);
  const [loading, setLoading] =useState(false);
  const history = useHistory();

  const handleEndTrip = async () => {
    setLoading(true);
    try {
      if (paymentStatus === "postpaid") {
        await new Promise(resolve => setTimeout(resolve, 3000));
        setLoading(false);        
        setShowModal(true);
      }
      else {
        await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/${bookingId}/endTrip`, {
          status: "completed",
          driverId
        });
        onTripEnded();
        await new Promise(resolve => setTimeout(resolve, 3000));
        setLoading(false);
      }
    }
    catch (err) {
      await new Promise(resolve => setTimeout(resolve, 3000));
      setLoading(false);
    }
  };

  const handleMarkAsPaid = async () => {
    setLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/${bookingId}/payment`, {
        paymentStatus: 'paid',
        paymentAmount: paymentAmount,
        isAmountPaid: 1
      });
      onTripEnded();
      await new Promise(resolve => setTimeout(resolve, 3000));
      setLoading(false);
      setShowModal(false); // Close the modal after successful payment
    } catch (err) {
      await new Promise(resolve => setTimeout(resolve, 3000));
      setLoading(false);
    }
  };

  const handleTripDetails = (bookingId, tourId) => {
    history.push(`/trip-details/${bookingId}/${tourId}`);
  }

  return (
    <>
    {loading ? (
            <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
              <RotatingLines
    visible={true}
    height="96"
    width="96"

    strokeWidth="5"
    animationDuration="0.75"
    ariaLabel="rotating-lines-loading"
    wrapperStyle={{}}
    wrapperClass=""
                colors='#262B40'
              />
              <p className="mt-2 fw-semibold">Just a moment, processing your request.</p>
            </div>
          ) : <></>}
      <Card className="border-0">
        <Card.Body className="px-0 py-0">
          <div>
          <div className="d-flex justify-content-start align-items-center">
            <div>
              <span className="circle-svg text-center w-20 f-12">
                <FontAwesomeIcon icon={faMapMarked} className="progress-label text-secondary mt-1" />
              </span>
            </div>
            <div className="px-2 py-2">
              <p className="f-14 mb-0 fw-semibold">
                {title}
              </p>
            </div>
          </div>
            <div className="location-wrapper">
              <div className="d-flex justify-content-start align-items-center">
                <div>
                  <span className="circle-svg text-center w-20 f-14">
                    <FontAwesomeIcon icon={faLocationArrow} className="progress-label text-secondary mt-1" />
                  </span>
                </div>
                <div className="px-2 py-2">
                  <p className="f-14 mb-0 fw-semibold">
                    {ShortenAddress(pickUpPoint)}
                  </p>
                </div>
              </div>
              <div className="dashed-line text-center">
                <span className="timeDetails upperCase-keyword">
                  {FormatTz(pickUpDate)} {moment(pickUpTime, 'HH:mm:ss').format('hh:mm A')}
                </span>
              </div>
              <div className="d-flex justify-content-start align-items-center mt-2">
                <span className="circle-svg text-center w-20 f-14">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="progress-label text-danger mt-1" />
                </span>
                <div className="px-2 py-2">
                  <p className="f-14 mb-0 fw-semibold">
                    {ShortenAddress(dropPoint)}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-between align-items-center">
              <span bg='tertiary' variant="primary" size="sm" className="px-2 upperCase-keyword fw-regular">RM {fare}</span>
              <Badge bg='tertiary' variant="primary" size="sm" className="badge-lg px-2 upperCase-keyword fw-regular">{paymentStatus}</Badge>
            </div>
            <div className="mt-3 modal-footer border-0 px-0 py-0">
              <Button size="md" variant="outline-primary" className="upperCase-keyword detail-button animate-up-2" onClick={() => handleTripDetails(bookingId, tourId)}>
                <span className="fw-bold upperCase-keyword">
                  Details
                </span>
              </Button>
              <Button variant="warning" className="upperCase-keyword detail-button mx-0" onClick={handleEndTrip}>
                <span className="fw-bold"> end trip </span>
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Collect Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="paymentAmount">
              <p className="fw-normal f-14 mb-3">Please collect the payment in cash from the passenger before ending the trip. This is a postpaid trip</p>
              <p className="mb-0 mt-1 fw-bold">RM {fare}</p>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleMarkAsPaid}>
            Mark as Paid
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export const CounterWidget = (props) => {
  const { additionalClass, tourId, bookingId, title, pickUpDate, pickUpTime, pickUpPoint, dropPoint, onTripStart, mapUrl, pax, fare } = props;
  const [error, setError] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);  // State to manage button enable/disable
  const [timeRemaining, setTimeRemaining] = useState(null);  // State to manage remaining time
  const [loading, setLoading] =useState(false);
  const [tripStarted, setTripStarted] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // This effect will re-run when tripStarted changes
    if (tripStarted) {
      // You can do anything else here if needed when the trip has started
      setTimeout(() => {
        // Reset the tripStarted state after a short delay to simulate the component reload
        setTripStarted(false);
      }, 3000); // Reset after 3 seconds
    }
  }, [tripStarted]);

  // Calculate the time difference and enable the button 1 hour before the trip
  useEffect(() => {
    // Convert pickUpDate to Malaysia timezone
    const tripStartTime = moment.tz(`${pickUpDate.split(" ")[0]} ${pickUpTime}`, "YYYY-MM-DD HH:mm:ss", "Asia/Kuala_Lumpur");

    // Get the current time in the same timezone
    const currentTime = moment.tz("Asia/Kuala_Lumpur");

    // Calculate the difference in minutes
    const minutesDiff = tripStartTime.diff(currentTime, 'minutes');

    let timeRemaining = '';

    if (minutesDiff <= 60) {
      // Show time in minutes
      timeRemaining = `${minutesDiff} min${minutesDiff > 1 ? 's' : ''}`;
    } else if (minutesDiff < 1440) { // Less than 24 hours
      // Show time in hours and minutes
      const hours = Math.floor(minutesDiff / 60); // Calculate hours
      const minutes = minutesDiff % 60; // Calculate minutes
      timeRemaining = `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
    } else { // More than 24 hours
      // Show time in days, hours, and minutes
      const days = Math.floor(minutesDiff / 1440); // 1440 minutes = 1 day
      const hours = Math.floor((minutesDiff % 1440) / 60); // Remaining hours after days
      const minutes = minutesDiff % 60; // Remaining minutes after hours
      timeRemaining = `${days} day${days > 1 ? 's' : ''} ${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
    }

    setTimeRemaining(timeRemaining);

    // Enable or disable button based on time remaining
    setIsButtonEnabled(minutesDiff <= 60);

  }, [pickUpDate, pickUpTime]);  // Recalculate if pickUpDate or pickUpTime changes


  const handleStartTrip = async () => {
    setLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/${bookingId}/status`, {
        status: "inProgress"
      });
      window.location.reload();
      setTripStarted(true); // Set tripStarted to true to trigger re-render
      onTripStart();
    }
    catch (err) { }
    setLoading(false);
  };

  const handleOpenMap = (address) => {
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&${address}`;
    window.open(mapsUrl, '_blank'); // Opens the Google Maps URL in a new tab
  };

  const handleTripDetails = (bookingId, tourId) => {
    history.push(`/trip-details/${bookingId}/${tourId}`);
  }

  return (
    <>
    {loading ? (
      <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
        <RotatingLines
visible={true}
height="96"
width="96"

strokeWidth="5"
animationDuration="0.75"
ariaLabel="rotating-lines-loading"
wrapperStyle={{}}
wrapperClass=""
          colors='#262B40'
        />
        <p className="mt-2 fw-semibold">Just a moment, processing your request.</p>
      </div>
    ) : <></>}

    <Card border="light" className={`${additionalClass}`}>
      <Card.Body className="py-3 px-3">
      <div className="d-flex justify-content-start align-items-center">
            <div>
              <span className="circle-svg text-center w-20 f-12">
                <FontAwesomeIcon icon={faMapMarked} className="progress-label text-secondary mt-1" />
              </span>
            </div>
            <div className="px-2 py-2">
              <p className="f-14 mb-0 fw-semibold">
                {title}
              </p>
            </div>
          </div>
        <div className="location-wrapper">
          <div className="d-flex justify-content-start align-items-center">
            <div>
              <span className="circle-svg text-center w-20 f-12">
                <FontAwesomeIcon icon={faLocationArrow} className="progress-label text-secondary mt-1" />
              </span>
            </div>
            <div className="px-2 py-2">
              <p className="f-14 mb-0 fw-semibold">
                {ShortenAddress(pickUpPoint)}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-center mt-2">
            <span className="circle-svg text-center w-20 f-14">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="progress-label mt-1" />
            </span>
            <div className="px-2 py-2">
              <p className="f-14 mb-0 fw-semibold">
                {ShortenAddress(dropPoint)}
              </p>
            </div>
          </div>
          </div>
          <div className="my-2">
          <div>
            <span className="timeDetails f-14 upperCase-keyword">
              {FormatTz(pickUpDate)}, {moment(pickUpTime, 'HH:mm:ss').format('hh:mm A')}
            </span>
            <span className="timeDetails f-14 upperCase-keyword">
              <FontAwesomeIcon icon={faUsers} className="me-1"/>{pax}
            </span>
            <span className="timeDetails f-14 upperCase-keyword">
              RM {fare}
            </span>
          </div>
          </div>

        {/* Display error message if there is an error */}
        {error && (
          <div className="text-danger mt-2 fs-12">
            {error}
          </div>
        )}
        {!isButtonEnabled && (
          <div className="float-info alert-warning">
            <p className="f-12 fw-semibold mb-0">
              Starts In: {timeRemaining}.
            </p>
          </div>
        )}
        <div className="d-flex modal-footer border-0 px-0 py-0">
          <Button size="md" variant="outline-primary" className="upperCase-keyword detail-button animate-up-2" onClick={() => handleOpenMap(mapUrl)} >
            <span className="fw-bold upperCase-keyword">
              Show Direction
            </span>
          </Button>
          <Button variant="success" className="upperCase-keyword detail-button" onClick={handleStartTrip} disabled={!isButtonEnabled} >
            <span className="fw-bold upperCase-keyword">
              start trip 
            </span>
          </Button>
          <Button size="md" variant="outline-primary" className="upperCase-keyword detail-button animate-up-2" onClick={() => handleTripDetails(bookingId, tourId)}>
            <span className="fw-bold upperCase-keyword">
              Details </span>
          </Button>
        </div>
        {/* Show message to the driver when the button is disabled */}
      </Card.Body>
    </Card>
    </>
  );
};

export const CompletedTourWidget = (props) => {
  const { additionalClass, tourId, bookingId, title, pickUpDate, pickUpTime, pickUpPoint, dropPoint, onTripStart, mapUrl, fare } = props;
  const [error, setError] = useState(null);
  const history = useHistory();
  const handleTripDetails = (bookingId, tourId) => {
    history.push(`/trip-details/${bookingId}/${tourId}`);
  }

  return (
    <Card border="light" className={`upperCase-keyword ${additionalClass}`}>
      <Card.Body className="py-3 px-3">
      <h6 className="f-20 mb-3">{ShortenAddress(dropPoint)}</h6>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div>
            <span className="card-subtitle d-block">{FormatTz(pickUpDate)}, {FormatTimeTz(pickUpDate)}</span>
             <span className="card-subtitle">RM {fare}</span>
            </div>
            <div className="d-flex modal-footer border-0 px-0 py-0">
            <Button size="md" variant="outline-primary" className="upperCase-keyword detail-button animate-up-2" onClick={() => handleTripDetails(bookingId, tourId)}>
              <span className="fw-bold upperCase-keyword">
                Details </span>
            </Button>
            </div>
          </div>
        {/* Display error message if there is an error */}
        {error && (
          <div className="text-danger mt-2 fs-12">
            {error}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};


export const CabHistoryWidget = (props) => {
  const { tourId, bookingId, title, pickUpDate, pickUpTime, pickUpPoint, dropPoint, fare, status, hover, driverType } = props;
  const [expenseType, setExpenseType] = useState('');
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [otherDescription, setOtherDescription] = useState("");
  const [expenseAmount, setExpenseAmount] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const history = useHistory();


  const handleExpenseTypeChange = (e) => {
    if (e.target.value === "other" && otherDescription.trim()) {
      setExpenseType(e.target.value);
    }
    else{
      setExpenseType(e.target.value);
      setOtherDescription('');
    }
  };

  const handleAddExpense = () => {
    setShowAddExpenseModal(true);
  };

  const handleAddExpenseSubmit = async () => {
    try {
      if (expenseType === "other" && !otherDescription.trim()) {
        setError("Please describe the other expense.");
        return;
      }
      setError('')
      setSuccess('')
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/addExpense/${bookingId}`, {
        expenseAmount,
        expenseType,
        expenseDescription : otherDescription.trim() === '' ? '' : otherDescription.trim()
      });

      if (response.status === 200 || response.status === 201) {
        setSuccess(`Expense amount  RM${expenseAmount} of ${expenseType} added successfully`);
        setError('')
      } else {
        setSuccess('')
        setError('Unable to add expense. Please try again later.');
      }

      // setShowAddExpenseModal(false);
      // fetchTours(); // Refresh tour list after adding expense
    } catch (error) {
      setError('Unable to add expense');
    }
  };

  const handleCloseAddExpenseModal = () => {
    setShowAddExpenseModal(false);
    setExpenseAmount('');
    setExpenseType('')
  };

  const handleTripDetails = (bookingId, tourId) => {
    history.push(`/trip-details/${bookingId}/${tourId}`);
  }

  return (
    <>
      <Card className={`mb-2 ${hover ? 'shadow hoverCard' : ''} ${!hover ? 'border-0' : ''}`}>
        <Card.Body className={`${!hover ? 'px-0 py-0' : ''}`}>
        <div className="d-flex justify-content-start align-items-center">
            <div className="py-2">
              <p className="f-14 mb-0 fw-semibold">
                {title}
              </p>
            </div>
          </div>

          <h6 className="f-20 mb-3">{ShortenAddress(dropPoint)}</h6>
          {/* <p className='mb-0 f-14'>{moment(pickUpDate).format('MMM DD')} {moment(pickUpTime, 'HH:mm:ss').format('hh:mm A')}</p> */}
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div>
              <span className="card-subtitle d-block">{FormatTz(pickUpDate)}, {FormatTimeTz(pickUpDate)}</span>
              {status !== 'cancelled' && <span className="card-subtitle">RM {fare}</span>}
            </div>
            <TripStatus tripData={props} />
          </div>

          <div className="d-flex modal-footer border-0 px-0 py-0">
            <Button size="md" variant="outline-primary" className="upperCase-keyword detail-button animate-up-2" onClick={() => handleTripDetails(bookingId, tourId)}>
              <span className="fw-bold upperCase-keyword">
                Details </span>
            </Button>
            <Button size="md" variant="dark" className="upperCase-keyword detail-button animate-up-2 mx-2 me-0" onClick={handleAddExpense} >
              <span className="fw-bold"> Add expense </span>
            </Button>
          </div>
        </Card.Body>
      </Card>
      {/* Add Expense Modal */}
      <Modal border="light" className="shadow mb-6" centered show={showAddExpenseModal} onHide={handleCloseAddExpenseModal}>
        <Modal.Header className="pb-0">
          <Modal.Title className='h5'>Add expense
            <span className='f-12 fw-light d-block'>Please provide information about your leave.</span>
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseAddExpenseModal} />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddExpenseSubmit} className='form-input-pl-0'>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
              <div className="mb-2 required">
                <Form.Group controlId="leaveType">
                  <Form.Label>Expense Type:</Form.Label>
                  <select
                    name="leaveType"
                    value={expenseType}
                    onChange={handleExpenseTypeChange}
                    className="form-control"
                  >
                    <option value="Open this select menu">Open this select menu</option>
                    {driverType !== "freelancer" && <>
                      <option value="fuel">Petrol Fee</option>
                      <option value="parking">Parking Charges</option>
                      <option value="maintenance">Maintenance</option>
                      <option value="carWash">Car Wash</option>
                      <option value="toll">Toll Tax</option>
                    </>
                    }
                    <option value="other">other</option>
                  </select>
                </Form.Group>
              </div>
            {expenseType === "other" && (
                  <div className="mb-2">
                    <Form.Group controlId="otherDescription">
                      <Form.Label>Describe Other Expense:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Describe the expense"
                        value={otherDescription}
                        onChange={(e) => setOtherDescription(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </div>
              )}
              <div className="mb-2">
                <Form.Group controlId="remarks">
                  <Form.Label>Amount:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder='Enter amount'
                    value={expenseAmount}
                    onChange={(e) => setExpenseAmount(e.target.value)} required
                  />
                </Form.Group>
              </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="md" className='f-14' onClick={handleCloseAddExpenseModal}>
            Close
          </Button>
          <Button variant="primary" size="md" className='f-14' onClick={handleAddExpenseSubmit}>
            Add Expense
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const MiniCabHistoryWidget = (props) => {
  const { dropPoint, fare, status } = props;

  return (
    <>
      <Card className='hoverCard mb-2'>
        <Card.Body>
          <div>
            <h6>{ShortenAddress(dropPoint)}</h6>
            {/* <p className='mb-0 f-14'>{moment(pickUpDate).format('MMM DD')} {moment(pickUpTime, 'HH:mm:ss').format('hh:mm A')}</p> */}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <Badge bg={`${status === 'pending' ? 'warning' : 'tertiary'}`} variant="primary" size="sm" className="badge-lg px-2 upperCase-keyword fw-regular">{status}</Badge>
            {status !== 'cancelled' && <span className="card-subtitle fw-bold">RM {fare}</span>}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export const ToursWidgetWithoutIcon = (props) => {
  const { title, pickUpDate, pickUpTime, pickUpPoint, dropPoint, fare, status } = props;

  return (
    <Row className="d-block">
      <Col>
        <div>
          <h5 className="mb-2 word-elipsis upperCase-keyword">{title}</h5>
          <div >
            <span className="card-subtitle">{FormatTz(pickUpDate)} {moment(pickUpTime, 'HH:mm:ss').format('hh:mm A')}</span>
          </div>
          <div className="small">
            <span className="fw-bold text-success">Pickup: </span>
            <small>{ShortenAddress(pickUpPoint)}</small>
          </div>
          <div className="small">
            <span className="fw-bold text-warning">Drop: </span>
            <small>{ShortenAddress(dropPoint)}</small>
          </div>
          <div className="mt-2">
            <span className="card-subtitle fw-bold">RM {fare}</span>
            <Badge bg={`${status === 'pending' ? 'warning' : 'tertiary'}`} variant="primary" size="sm" className="mx-2 me-2 card-subtitle badge-lg px-2 upperCase-keyword">{status}</Badge>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export const upcomingToursCard = (props) => {
  const { pickUpDate, pickUpTime, pickUpPoint, dropPoint, status } = props;

  return (
    <>
      <div className="d-flex justify-space-between align-items-center">
        <div>
          <span className="fw-bold upperCase-keyword text-success">
            <small>faag{ShortenAddress(pickUpPoint)}</small>
          </span>
        </div>
        <div className="dashed-line">
          <span className="timeDetails upperCase-keyword text-danger">
            {FormatTz(pickUpDate)}
            <br />
            {moment(pickUpTime, 'HH:mm:ss').format('hh:mm A')}
          </span>
        </div>
        <div className="small">
          <span className="fw-bold upperCase-keyword text-warning">
            <small>{ShortenAddress(dropPoint)}</small>
          </span>
        </div>
      </div>
      <div>
        <span className="card-subtitle">{status}</span>
      </div>
    </>
  );
};


export const CircleChartWidget = (props) => {
  const { title, data = [] } = props;
  const series = data.map(d => d.value);

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xs={12} xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <CircleChart series={series} />
          </Col>
          <Col xs={12} xl={7} className="px-xl-0">
            <h5 className="mb-3">{title}</h5>

            {data.map(d => (
              <h6 key={`circle-element-${d.id}`} className="fw-normal text-gray">
                <FontAwesomeIcon icon={d.icon} className={`icon icon-xs text-${d.color} w-20 me-1`} />
                {` ${d.label} `}{`${d.value}%`}
              </h6>
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const BarChartWidget = (props) => {
  const { title, value, percentage, data = [] } = props;
  const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const series = data.map(d => d.value);
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
        <div className="d-block">
          <h6 className="fw-normal text-gray mb-2">{title}</h6>
          <h3>{value}</h3>
          <small className="mt-2">
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={`${percentageColor} fw-bold`}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-block ms-auto">
          {data.map(d => (
            <div key={`bar-element-${d.id}`} className="d-flex align-items-center text-end mb-2">
              <span className={`shape-xs rounded-circle bg-${d.color} me-2`} />
              <small className="fw-normal">{d.label}</small>
            </div>
          ))}
        </div>
      </Card.Body>
      <Card.Body className="p-2">
        <BarChart labels={labels} series={series} />
      </Card.Body>
    </Card>
  );
};

export const TeamMembersWidget = () => {
  const TeamMember = (props) => {
    const { name, statusKey, image, icon, btnText } = props;
    const status = {
      online: { color: "success", label: "Online" },
      inMeeting: { color: "warning", label: "In a meeting" },
      offline: { color: "danger", label: "Offline" }
    };

    const statusColor = status[statusKey] ? status[statusKey].color : 'danger'
      , statusLabel = status[statusKey] ? status[statusKey].label : 'Offline';

    return (
      <ListGroup.Item className="px-0">
        <Row className="align-items-center">
          <Col className="col-auto">
            <a href="#top" className="user-avatar">
              <Image src={image} className="rounded-circle" />
            </a>
          </Col>
          <Col className="ms--2">
            <h4 className="h6 mb-0">
              <a href="#!">{name}</a>
            </h4>
            <span className={`text-${statusColor}`}>● </span>
            <small>{statusLabel}</small>
          </Col>
          <Col className="col-auto">
            <Button variant="tertiary" size="sm">
              <FontAwesomeIcon icon={icon} className="me-1" /> {btnText}
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">Recent Activities</h5>
        <Button variant="secondary" size="sm">See all</Button>
      </Card.Header>
      <Card.Body>
        <ListGroup className="list-group-flush list my--3">
          {/* {teamMembers.map(tm => <TeamMember key={`team-member-${tm.id}`} {...tm} />)} */}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export const ProgressTrackWidget = () => {
  const Progress = (props) => {
    const { title, percentage, icon, color, last = false } = props;
    const extraClassName = last ? "" : "mb-2";

    return (
      <Row className={`align-items-center ${extraClassName}`}>
        <Col xs="auto">
          <span className={`icon icon-md text-${color}`}>
            <FontAwesomeIcon icon={icon} className="me-1" />
          </span>
        </Col>
        <Col>
          <div className="progress-wrapper">
            <div className="progress-info">
              <h6 className="mb-0">{title}</h6>
              <small className="fw-bold text-dark">
                <span>{percentage} %</span>
              </small>
            </div>
            <ProgressBar variant={color} now={percentage} min={0} max={100} />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header className="border-bottom border-light">
        <h5 className="mb-0">Progress track</h5>
      </Card.Header>
      <Card.Body>

        <Progress title="Rocket - SaaS Template" color="purple" icon={faBootstrap} percentage={34} />
        <Progress title="Pixel - Design System" color="danger" icon={faAngular} percentage={60} />
        <Progress title="Spaces - Listings Template" color="tertiary" icon={faVuejs} percentage={45} />
        <Progress title="Stellar - Dashboard" color="info" icon={faReact} percentage={35} />
        <Progress last title="Volt - Dashboard" color="purple" icon={faBootstrap} percentage={34} />
      </Card.Body>
    </Card>
  );
};

export const RankingWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
          <div>
            <h6><FontAwesomeIcon icon={faGlobeEurope} className="icon icon-xs me-3" /> Global Rank</h6>
          </div>
          <div>
            <Card.Link href="#" className="text-primary fw-bold">
              #755 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFlagUsa} className="icon icon-xs me-3" />Country Rank</h6>
            <div className="small card-stats">
              United States <FontAwesomeIcon icon={faAngleUp} className="icon icon-xs text-success ms-2" />
            </div>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #32 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between pt-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFolderOpen} className="icon icon-xs me-3" />Category Rank</h6>
            <Card.Link href="#top" className="small card-stats">
              Travel &gt; Accomodation
            </Card.Link>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #16 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const SalesValueWidget = (props) => {
  const { title, value, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Card className="bg-secondary-alt shadow-sm">
      <Card.Header className="d-flex flex-row align-items-center flex-0">
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            {title}
          </h5>
          <h3>${value}</h3>
          <small className="fw-bold mt-2">
            <span className="me-2">Yesterday</span>
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={percentageColor}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-flex ms-auto">
          <Button variant="secondary" size="sm" className="me-2">Month</Button>
          <Button variant="primary" size="sm" className="me-3">Week</Button>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChart />
      </Card.Body>
    </Card>
  );
};

export const SalesValueWidgetPhone = (props) => {
  const { title, value, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Card className="bg-secondary-alt shadow-sm">
      <Card.Header className="d-md-flex flex-row align-items-center flex-0">
        <div className="d-block mb-3 mb-md-0">
          <h5 className="fw-normal mb-2">
            {title}
          </h5>
          <h3>${value}</h3>
          <small className="fw-bold mt-2">
            <span className="me-2">Yesterday</span>
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={percentageColor}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-flex ms-auto">
          <Button variant="secondary" size="sm" className="me-2">Month</Button>
          <Button variant="primary" size="sm" className="me-3">Week</Button>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChartphone />
      </Card.Body>
    </Card>
  );
};

export const AcquisitionWidget = () => {
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <h5>Acquisition</h5>
        <p>Tells you where your visitors originated from, such as search engines, social networks or website referrals.</p>
        <div className="d-block">
          <div className="d-flex align-items-center pt-3 me-5">
            <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="d-block">
              <label className="mb-0">Bounce Rate</label>
              <h4 className="mb-0">33.50%</h4>
            </div>
          </div>
          <div className="d-flex align-items-center pt-3">
            <div className="icon icon-shape icon-sm icon-shape-quaternary rounded me-3">
              <FontAwesomeIcon icon={faChartArea} />
            </div>
            <div className="d-block">
              <label className="mb-0">Sessions</label>
              <h4 className="mb-0">9,567</h4>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};


export const TilesWidget = (props) => {
  const { category, title, subTitle, textColor } = props;

  return (
    <Card border="light" className="shadow-sm mb-2">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={6} md={6} className="text-xl-center mb-0">
            <h5 className="f-16">{category}</h5>
            <h3 className={`mb-0 ${textColor} f-22`}>{subTitle} {title}</h3>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
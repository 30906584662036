import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Preloader from './Preloader';
import { ProfileCardWidget } from './Widgets';
import SettingsNav from './SettingsNav';

export default ({ userData }) => {
  const [user, setUser] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchDriverProfile?driverId=${userData.id}`);
      setUser(response.data); // Assuming response.data contains user details
    } catch (error) {
      setUser([])
    }
  }, [userData]);

  useEffect(() => {
    fetchUserData();
  }, [userData]);

  if (!user) {
    return <><Preloader /></>; // You can customize loading indicator as per your design
  }

  return (
    <div>
      <div>
        <ProfileCardWidget photo={user.image} userName={`${user.firstName} ${user.lastName}`} contactNo={user.contactNumber} email={user.email} />
      </div>
      <SettingsNav data={userData} />
    </div>
  );
};
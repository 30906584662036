import React, { useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPersonBooth, faUnlockAlt, faCalendarAlt, faCar, faCarSide, faIdCardAlt, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, Container, InputGroup, Spinner, Alert, Image } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from '../../routes';
import TvgLogo from '../../assets/img/brand/TV-Logo.png';
import DefaultImage from '../../assets/img/user.png';

const InputField = ({ label, icon, ...props }) => (
  <Form.Group className="mb-4 required">
    <Form.Label>{label}</Form.Label>
    <InputGroup>
      {icon ? <InputGroup.Text>
        <FontAwesomeIcon icon={icon} />
      </InputGroup.Text>
        : <></>}
      <Form.Control {...props} />
    </InputGroup>
  </Form.Group>
);

const DriverSignUpPage = () => {
  const history = useHistory();
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthday: '',
    address: '',
    joinedDate: '',
    drivingLicense: '',
    contactNumber: '',
    password: '',
    confirmPassword: '',
    userRole: 'driver',
    profileImage: null,
    profileImagePreview: DefaultImage,
    error: '',
    success: '',
    loading: false,
    validUpto: ''
    });

  const scrollRef = useRef();
  const scrollToMessageAfterSubmitForm = () => {
    if (scrollRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: scrollRef.current.offsetTop,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const changeLicenceExpiryDate = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const MAX_SIZE_MB = 5;
    const ALLOWED_EXTENSIONS = ["image/jpeg", "image/png", "image/gif"];

    if (file) {
      if (file.size > MAX_SIZE_MB * 1024 * 1024) {
        setFormData(prevState => ({ ...prevState, profileImage: null, error: 'File size exceeds 5 MB.' }));
        scrollToMessageAfterSubmitForm();
        return;
      }
      if (!ALLOWED_EXTENSIONS.includes(file.type)) {
        setFormData(prevState => ({ ...prevState, profileImage: null, error: 'Invalid file format.' }));
        scrollToMessageAfterSubmitForm();
        return;
      }
      const objectUrl = URL.createObjectURL(file);
      setFormData(prevState => ({ ...prevState, profileImage: file, profileImagePreview: objectUrl, error: '' }));
      return () => URL.revokeObjectURL(objectUrl);
    }
  };

  const uploadFile = async (file) => {
    const base64File = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/file-uploader?bucketType=profileImage&filename=${encodeURIComponent(file.name)}`, base64File, {
      headers: { 'Content-Type': file.type },
    });
    return response.data.message.fileLocation;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setFormData({ loading: true, error: '', success: '' });
    const { confirmPassword, password, birthday, joinedDate, contactNumber } = formData;

    if (password !== confirmPassword) {
      setFormData({ ...formData, error: 'Passwords do not match.' });
      scrollToMessageAfterSubmitForm();
      return
    }
    if (password.length < 8) {
      setFormData({ ...formData, error: 'Password must be at least 8 characters long.' });
      scrollToMessageAfterSubmitForm();
      return
    }
    if (!birthday) {
      setFormData({ ...formData, error: 'Enter birth and joining date.' });
      scrollToMessageAfterSubmitForm();
      return
    }

    if(!joinedDate) {
      setFormData({ ...formData, error: 'Enter joining date.' });
      scrollToMessageAfterSubmitForm();
      return
    }
    if (!/^\d{10}$/.test(contactNumber)) {
      setFormData({ ...formData, error: 'Enter a valid contact number.' });
      scrollToMessageAfterSubmitForm();
      return
    }

    if(!formData.profileImage){
      setFormData({ ...formData, error: 'Please upload your profile image.' });
      scrollToMessageAfterSubmitForm();
      return
    }

    setFormData(prevState => ({ ...prevState, loading: true, error: '', success: '' }));
    try {
      let imageUrl = '';

      try {
        if (formData.profileImage) {
          imageUrl = await uploadFile(formData.profileImage);
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        imageUrl = 'https://transferappdata.s3.ap-southeast-1.amazonaws.com/user+(2).png'; // Replace with default image URL
      }
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/signup`, { ...formData, imageUrl });

      if (response.status >= 200 && response.status < 300) {
        localStorage.setItem('token', response.data.token);
        setFormData({ ...formData, success: 'Signup successful.', loading: false });
        history.push('/');
      } else {
        setFormData({ ...formData, error: 'Failed to signup.', loading: false });
      }
    } catch (error) {
      setFormData({ ...formData, error: error.response?.data?.message || 'An error occurred.', loading: false });
    } finally {
      scrollToMessageAfterSubmitForm();
    }
  };

  return (
    <main>
        <section className="d-flex my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 p-lg-5 w-100 fmxw-800">
              <div className="mb-4 mb-lg-0 p-lg-5 w-100 fmxw-800">
                <div className="text-center mb-2">
                  <Image src={TvgLogo} className="w-48Percent" />
                </div>
                <h3 className="text-center mb-4">Create an account</h3>
                {formData.error && <Alert ref={scrollRef} variant="danger">{formData.error}</Alert>}
                {formData.success && <Alert ref={scrollRef} variant="success">{formData.success}</Alert>}
                <Form className="mt-4" onSubmit={onSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>Profile Image</Form.Label>
                    <div className='d-md-none'>
                      {formData.profileImagePreview && (
                        <div className="mt-2 mb-2 text-center">
                          <span>
                            <img src={formData.profileImagePreview} alt="Profile Preview" className="img-fluid  card-img avatar-xl rounded-circle signup-avatar" style={{ maxWidth: '100px' }} />
                          </span>
                        </div>
                      )}
                      <InputGroup>
                        <Form.Control
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </InputGroup>
                      {/* <ImageUploader /> */}
                    </div>
                    <div className='d-flex align-items-center justify-content-between  d-none d-sm-flex'>
                      {formData.profileImagePreview && (
                        <div className="mt-2 mr-30">
                          <span>
                            <img src={formData.profileImagePreview} alt="Profile Preview" className="img-fluid  card-img avatar-xl rounded-circle signup-avatar" style={{ maxWidth: '150px' }} />
                          </span>
                        </div>
                      )}
                      <InputGroup>
                        <Form.Control
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </InputGroup>
                      {/* <ImageUploader /> */}
                    </div>
                  </Form.Group>
                  <Row>
                    <Col lg={6} >
                      <InputField label="First Name" icon={faUser} name="firstName" value={formData.firstName} required onChange={handleInputChange} placeholder="First Name" />
                    </Col>
                    <Col lg={6} >
                      <InputField label="Last Name" icon={faUser} name="lastName" value={formData.lastName} required onChange={handleInputChange} placeholder="Last Name" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} >
                      <InputField label="Email" icon={faEnvelope} name="email" type="email" value={formData.email} required onChange={handleInputChange} placeholder="Email Address" />
                    </Col>
                    <Col>
                      <InputField label="Contact Number" icon={faPhoneAlt} name="contactNumber" value={formData.contactNumber} required onChange={handleInputChange} placeholder="Contact Number" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} >
                      <Form.Group className="mb-4 required">
                        <Form.Label>Date of birth</Form.Label>
                        <Datetime timeFormat={false} closeOnSelect onChange={handleDateChange('birthday')} value={formData.birthday}
                          renderInput={(props, openCalendar) => (
                            <InputGroup onClick={openCalendar}>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control readOnly required value={formData.birthday ? moment(formData.birthday).format('YYYY-MM-DD') : ''} />
                            </InputGroup>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-4 required">
                        <Form.Label>Joined date</Form.Label>
                        <Datetime timeFormat={false} closeOnSelect onChange={handleDateChange('joinedDate')} value={formData.joinedDate}
                          renderInput={(props, openCalendar) => (
                            <InputGroup onClick={openCalendar}>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control readOnly required value={formData.joinedDate ? moment(formData.joinedDate).format('YYYY-MM-DD') : ''} />
                            </InputGroup>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <InputField label="Driving License" icon={faIdCardAlt} name="drivingLicence" value={formData.drivingLicence} required onChange={handleInputChange} placeholder="Licence Number" />
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-4 required">
                        <Form.Label>Expiry Date</Form.Label>
                        <Datetime timeFormat={false} closeOnSelect onChange={changeLicenceExpiryDate('validUpto')} value={formData.validUpto}
                          renderInput={(props, openCalendar) => (
                            <InputGroup onClick={openCalendar}>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control readOnly required value={formData.validUpto ? moment(formData.validUpto).format('YYYY-MM-DD') : ''} />
                            </InputGroup>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <InputField label="Enter Address" name="address" value={formData.address} required as="textarea" rows="4" onChange={handleInputChange} placeholder="Enter Address" />
                  <InputField label="Password" icon={faUnlockAlt} name="password" type="password" value={formData.password} required onChange={handleInputChange} placeholder="Enter Password" />
                  <InputField label="Confirm Password" icon={faUnlockAlt} name="confirmPassword" type="password" value={formData.confirmPassword} required onChange={handleInputChange} placeholder="Confirm Password" />
                  <Button variant="primary" type="submit" disabled={formData.loading} className="w-100">
                    {formData.loading ? <Spinner animation="border" size="sm" /> : 'Create Account'}
                  </Button>
                </Form>
                <p className="mt-4 text-center">Already have an account? 
                  <p>
                    <Link to={Routes.Signin.path} className='text-decoration-none'>Sign In</Link>
                  </p>
                </p>
              </div>
              </div>
            </Col>
          </Row>
          </Container>
      </section>
    </main>
  );
};

export default DriverSignUpPage;

import React, { useState, useEffect, useCallback } from 'react';
import { Spinner, Alert } from '@themesberg/react-bootstrap';
import axios from 'axios';
import DocumentCard from './DocumentCard';
import { RotatingLines } from 'react-loader-spinner';

export default ({ data }) => {
    const [documents, setDocuments] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchDocuments = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/${data.id}/documents`
            );

            setDocuments(response.data);
        } catch (error) {
            setError('Error fetching documents');
        } finally {
            setLoading(false);
        }
    }, [data.id]);

    useEffect(() => {
        fetchDocuments();
    }, [data.id]);

    const handleSuccess = (message) => {
        setError(null);
        fetchDocuments(); // Reload the documents data after success
    };

    const handleError = (message) => {
        setError(message);
    };

    if (loading) {
        return <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
            <RotatingLines
                visible={true}
                height="96"
                width="96"

                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
                strokeColor='#262B40'
            />
            <p className="mt-2 fw-semibold">Just a moment, processing your request.</p>
        </div>;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <>
            <div className="my-4">
                {documents ? (
                    Object.keys(documents).map((field, index) => (
                        <DocumentCard
                            key={index}
                            field={field}
                            value={documents[field]}
                            driverId={data.id}
                            onSuccess={handleSuccess}
                            onError={handleError}
                        />
                    ))
                ) : (
                    <Alert variant="info">No documents available</Alert>
                )}
            </div>
        </>
    );
};

import React from 'react';
import { Card, Button, Row, Col, Badge } from '@themesberg/react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Avatar from '../../../../components/Utility/Avatar';
import { Link } from 'react-router-dom';

const ViolationCard = ({ violation }) => {
    const { violationId, category, subCategory, summary, description, priority, violationDate, driverName, adminName, attachments } = violation;

    // Format violation date
    const formattedDate = moment(violationDate).format('MMMM Do YYYY, h:mm A');
    const priorityColor = priority === 'high' ? 'danger' : priority === 'medium' ? 'warning' : 'success'; // Dynamic priority badge color

    return (
        <Link to={`/violation-details/${violationId}`}>
        <Card className="mb-4">
            <Card.Body>
                <h5 className="card-title">
                    Violation: <strong>{summary}</strong>
                </h5>
                <p className="card-text d-flex align-items-center justify-content-between">
                <div>
                        <h5>Violation Date:</h5>
                        <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>

                            {/* <span>{Avatar(adminName.split(" ")[0], adminName.split(" ")[1])}</span> */}
                            <div>
                                <span className='fw-normal'>
                                    {formattedDate}
                                </span>
                            </div>
                        </div>
                    </div>                    <div>
                        <h5>Reported By:</h5>
                        <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>

                            {/* <span>{Avatar(adminName.split(" ")[0], adminName.split(" ")[1])}</span> */}
                            <div>
                                <span className='fw-normal'>
                                    {adminName}
                                </span>
                            </div>
                        </div>
                    </div>
                </p>
            </Card.Body>
        </Card>
        </Link>
    );
};

export default ViolationCard;

import React from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';

export default ({ data }) => {
    return (
        <Row>
            <Col xs={12} md={12}>
                {/* Wallet Details Card */}
                {data && (
                    <>

                        <Row>
                            <Col xs={12} md={12}>
                                <div className='d-flex justify-content-between align-items-center mb-1'>
                                    <div>
                                        <p className="mb-1 text-muted f-14">Welcome Back</p>
                                        <h4 className="mb-0 f-18 text-capitalize">{data.username}</h4>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </Col>
        </Row>
    );
};

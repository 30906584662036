import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GroupedExpenses from './GroupedExpenses';

const ExpensesList = ({data}) => {
  console.log(data)
  const [expenses, setExpenses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchExpenses();
  }, [currentPage]); // Reload expenses when currentPage changes

  const fetchExpenses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/expenses?driverId=${data.id}`);
      setExpenses(response.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };


  return (
    <div>
      <h2 className="my-4">Expenses List</h2>
        <GroupedExpenses expenses={expenses} />
    </div>
  );
};

export default ExpensesList;

import React from 'react';
import { Row, Col, Button, Image } from '@themesberg/react-bootstrap';
import Avatar from '../../../components/Utility/Avatar';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';
import NotificationsWrapper from '../../../components/widgets/NotificationsWrapper';
import Chat from "../../../assets/img/icons/chat1.svg";
import ToggleSwitch from '../../../components/ToggleSwitch';

export default ({ data }) => {
    console.log(data)
    const history = useHistory();

    const handleAiChatSection = () => {
        history.push(Routes.AIChat.path);
    }

    const handleSettingPath = () => {
        history.push(Routes.Settings.path);
    };

    return (
        <Row>
            <Col xs={12} md={12}>
                {/* Wallet Details Card */}
                {data && (
                    <>

                        <Row>
                            <Col xs={12} md={12}>
                                <div className='d-flex justify-content-between align-items-center mb-1'>
                                    <Button className="border-0 p-0 box-shadow-0" onClick={handleSettingPath}>
                                        {Avatar(data.username.split(" ")[0], data.username.split(" ")[1])}
                                    </Button>
                                    {data.driverType === "freelancer" && <div className='driverAvailabilitySwitch'>
                                        <ToggleSwitch driverId={data.id} />
                                    </div>}
                                    <div className='d-flex justify-content-between align-items-center'>
                                        {data.driverType === "inHouse" && <Button className="border-0 p-0 box-shadow-0 bg-transparent" onClick={handleAiChatSection}>
                                                <span className="icon navbar-avatar-32 mt-1 vta-m">
                                                    <Image src={Chat} className="" />
                                                </span>
                                            </Button>}
                                        <NotificationsWrapper data={data} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </Col>
        </Row>
    );
};

import React, { useState } from "react";
import axios from "axios";
import { Button, Form, InputGroup } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OpenApiChat = () => {
    const history = useHistory();
    const [input, setInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    // Function to check if the input is travel-related
    const isTravelRelated = (query) => {
        const travelKeywords = [
            "flight", "hotel", "tour", "destination", "vacation", "trip", "holiday", "weather", "activity", "transport",
            "visa", "booking", "guide", "adventure", "beach", "mountain", "museum", "sightseeing", "restaurant", "local",
            "city", "cruise", "travel insurance", "itinerary", "inclusions", "history", "sightseeing tour", "guided tour",
            "private tour", "group tour", "luxury tour", "city tour", "eco tour", "activities", "excursions", "attractions",
            "budget", "cost", "price", "deal", "promotion", "package", "departure", "arrival", "dates", "schedule", "time",
            "season", "best time to visit", "visa", "passport", "transportation", "vehicle", "bus", "train", "taxi", "pickup",
            "drop-off", "reservation", "confirm", "cancel", "modify", "availability", "tour guide", "local guide", "hotel",
            "motel", "guesthouse", "accommodation", "airbnb"
        ];
        
        return travelKeywords.some(keyword => query.toLowerCase().includes(keyword));
    };

    const handleSendMessage = async () => {
        if (!input.trim()) return;

        // Add user message to the chat
        setMessages((prevMessages) => [...prevMessages, { sender: "user", text: input }]);
        setLoading(true);
        setInput("");

        // Check if the query is travel-related
        if (!isTravelRelated(input)) {
            // If not travel-related, respond with a default message
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: "gpt", text: "Sorry, I can only assist with travel-related queries. Please ask something related to trips, destinations, or tours." },
            ]);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(
                "https://api.openai.com/v1/chat/completions",
                {
                    model: "gpt-3.5-turbo",
                    messages: [
                        { role: "system", content: "You are a travel assistant. Please only answer questions related to travel, trips, tours, and destinations." },
                        ...messages.map((msg) => ({
                            role: msg.sender === "user" ? "user" : "assistant",
                            content: msg.text,
                        })),
                        { role: "user", content: input },
                    ],
                },
                {
                    headers: {
                        "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // Add GPT response to the chat
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: "user", text: input },
                { sender: "gpt", text: response.data.choices[0].message.content },
            ]);
        } catch (error) {
            console.error("Error:", error);
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: "error", text: "Error occurred. Please try again." },
            ]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 pt-0">
                <div className="d-block mb-md-0">
                    <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faArrowLeft} size="md" className="me-2" />{" "}
                        <h5 className="d-inline-block">Trip Helper</h5>
                    </p>
                </div>
            </div>
            <p>Welcome to TVG Trip AI! Please ask only questions related to tours, travel, and bookings, and I'll be happy to assist you.</p>
            <div className="chat-container bg-D1E5E6 card">
                <div className="chat-messages">
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            className={`mb-4 ${msg.sender === "user" ? "" : "text-dark"}`}
                            style={{
                                textAlign: msg.sender === "user" ? "right" : "left",
                                marginBottom: "12px",
                            }}
                        >
                            <div
                                style={{
                                    color:"#000",
                                    maxWidth: "80%",
                                    marginLeft: msg.sender === "user" ? "20%" : "0",
                                    marginRight: msg.sender === "user" ? "0" : "20%",
                                    wordWrap: "break-word",
                                }}
                            >
                                <strong>{msg.sender === "user" ? "You" : "TVG Trip AI "}:</strong>{" "}
                                {msg.text}
                            </div>
                        </div>
                    ))}
                    {loading && (
                        <div className="text-center text-muted">
                            <i>Loading...</i>
                        </div>
                    )}
                </div>

                <div className="chat-input mt-3">
                    <Form.Group id="messageInput" className="mb-2">
                        <InputGroup>
                            <Form.Control
                                autoFocus
                                required
                                type="text"
                                placeholder="Type a message"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Button
                        variant="primary"
                        onClick={handleSendMessage}
                        className="w-100 mt-2"
                        disabled={loading}
                    >
                        {loading ? "Sending..." : "Send"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default OpenApiChat;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLandmark, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { Col, Row, Button, Spinner, Card, Form, Alert, InputGroup, Modal } from '@themesberg/react-bootstrap';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

export default ({ data, reloadLeaves }) => {
  const [loading, setLoading] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [formData, setFormData] = useState({
    driverId: data.id,
    leaveType: '',
    leaveStartDate: '',
    leaveEndDate: '',
    leaveStatus: 'pending', // Default status is 'pending'
    createdBy: 'driver', // Default createdBy is 'driver'
    remarks: '',
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectOptions, setSelectOptions] = useState([]); // Options for drivers
  const scrollRef = React.useRef(null);

  const scrollToMessageAfterSubmitForm = () => {
    window.scrollTo({
      behavior: "smooth",
      top: scrollRef.current
    });
  };

  const validateForm = () => {
    let error = '';
    let isValid = true;

    if (!formData.leaveStartDate || !formData.leaveEndDate) {
      error = !formData.leaveStartDate ? 'Leave start date is required' : 'Leave end date is required';
      isValid = false;
    }
  
    if (formData.leaveStartDate && formData.leaveEndDate) {
      const startDate = moment(formData.leaveStartDate);
      const endDate = moment(formData.leaveEndDate);
  
      if (startDate.isAfter(endDate)) {
        error = 'Leave start date must be before the leave end date';
        isValid = false;
      }
    }

    if(formData.leaveType === '' || formData.leaveType === "Open this select menu") {
      error = 'Select leave type correctly';
      isValid = false;
    }

    if (formData.driverId === '') {
      error = 'Select driver';
      isValid = false;
    }

    setError(error);
    return isValid;
  };

  const handleClose = () => setShowDefault(false);

  const handleLeaveTypeChange = (e) => {
    setFormData({
      ...formData,
      leaveType: e.target.value,
    });
  };

  const handleStartDateChange = (date) => {
    setFormData({
      ...formData,
      leaveStartDate: moment(date).format('YYYY-MM-DD'),
    });
  };

  const handleEndDateChange = (date) => {
    setFormData({
      ...formData,
      leaveEndDate: moment(date).format('YYYY-MM-DD'),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();

    if (isValid) {
      // setLoading(true);
      clearMessages();
      try {
        const formatStartDate = moment
        .utc(formData.leaveStartDate) // Convert the date to UTC
        .tz('Asia/Kuala_Lumpur') // Convert it to Malaysia time
        .format('YYYY-MM-DD HH:mm:ss'); // Final formatted datetime string
    
    const formatEndDate = moment
        .utc(formData.leaveEndDate) // Convert the date to UTC
        .tz('Asia/Kuala_Lumpur') // Convert it to Malaysia time
        .format('YYYY-MM-DD HH:mm:ss'); // Final formatted datetime string

        await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/leaves`, {
          ...formData, formatStartDate, formatEndDate
        });

        setSuccessMessage('Leave request submitted successfully.');
        reloadLeaves();
      } catch (error) {
        if (error.response) {
          handleRequestError(error.response.data.message);
        } else if (error.request) {
          handleRequestError(error.request);
        } else {
          handleRequestError(error.message);
        }
      } finally {
        setLoading(false);
        scrollToMessageAfterSubmitForm();
        resetForm();
      }
    }
  };

  const handleRequestError = (errorMessage) => {
    setError(errorMessage);
    console.error(errorMessage);
  };

  const handleRemarksChange = (e) => {
    setFormData({
      ...formData,
      remarks: e.target.value,
    });
  };

  const clearMessages = () => {
    setError('');
    setSuccessMessage('');
  };

  const resetForm = () => {
    setFormData({
      driverId: data.id,
      leaveType: '',
      leaveStartDate: '',
      leaveEndDate: '',
      leaveStatus: 'pending',
      createdBy: 'driver',
      remarks: '',
    });
  };

  const valid = function (current) {
    return current.isAfter(moment().subtract(1, 'day'));
  };

  const handleLeaveReasonChange = (e) => {
    setFormData({
      ...formData,
      leaveReason: e.target.value,
    });
  };

  return (
    <>
      <Button variant="primary" size="sm" className='animate-up-2 mb-2' onClick={() => setShowDefault(true)} >
        <FontAwesomeIcon className="me-1" icon={faPlusCircle} /> New Leave
      </Button>
      <Modal border="light" className="shadow mb-6" centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className='h5'>Create Leave Plan
            <span className='f-12 fw-light d-block'>Please provide information about your leave.</span>
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="skeleton-loading">
              <div className="mb-2">
                <Skeleton height={40} />
              </div>
              <div className="mb-2">
                <Skeleton height={40} />
              </div>
              <div className="mb-2">
                <Skeleton height={40} />
              </div>
              <div className="mb-2">
                <Skeleton height={40} />
              </div>
              <div className="mb-2">
                <Skeleton height={40} />
              </div>
            </div>
          ) : (
            <Form onSubmit={handleSubmit} className='form-input-pl-0'>
              {error && <Alert ref={scrollRef} variant="danger">{error}</Alert>}
              {successMessage && <Alert ref={scrollRef} variant="success">{successMessage}</Alert>}

              <Row>
                <Col xs={12} md={6} className="mb-2 required">
                  <Form.Group controlId="leaveStartDate">
                    <Form.Label>Leave Start Date:</Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={handleStartDateChange}
                      isValidDate={valid}
                      value={formData.leaveStartDate}
                      inputProps={{
                        name: 'leaveStartDate',
                        required: true,
                        placeholder: 'mm/dd/yyyy',
                      }}
                      renderInput={(props, openCalendar) => (
                        <InputGroup onClick={openCalendar}>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <input className="form-control" {...props} />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="mb-2 required">
                  <Form.Group controlId="leaveEndDate">
                    <Form.Label>Leave End Date:</Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={handleEndDateChange}
                      isValidDate={valid}
                      value={formData.leaveEndDate}
                      inputProps={{
                        name: 'leaveEndDate',
                        required: true,
                        placeholder: 'mm/dd/yyyy',
                      }}
                      renderInput={(props, openCalendar) => (
                        <InputGroup onClick={openCalendar}>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <input className="form-control" {...props} />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6} className="mb-2 required">
                  <Form.Group controlId="leaveType">
                    <Form.Label>Leave Type:</Form.Label>
                    <select
                      name="leaveType"
                      value={formData.leaveType}
                      onChange={handleLeaveTypeChange}
                      className="form-control"
                    >
                      <option value="Open this select menu">Open this select menu</option>
                      <option value="sick">Sick</option>
                      <option value="vacation">Vacation</option>
                      <option value="planned">Planned</option>
                      <option value="others">Others</option>
                    </select>
                  </Form.Group>
                </Col>
              </Row>
              {formData.leaveType === 'others' && (
                <Row>
                  <Col xs={12} className="mb-3">
                    <Form.Group controlId="leaveReason">
                      <Form.Label>Leave Reason:</Form.Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={formData.leaveReason}
                        onChange={handleLeaveReasonChange}
                        required={formData.leaveType === 'others'}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
                <Row>
                  <Col xs={12} className="mb-2">
                    <Form.Group controlId="remarks">
                      <Form.Label>Remarks:</Form.Label>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={formData.remarks}
                        onChange={handleRemarksChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

              <Row>
                <Col xs={12} className="mb-2">
                  <Button variant="primary" size="md" className='f-14' type="submit">
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

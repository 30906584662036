import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import ShortenAddress from '../../components/Utility/ShortenAddress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

export default (data) => {
  const [expenses, setExpenses] = useState();
  const history = useHistory();
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/expenses/driverView?driverId=${data.id}`);
        const expData = response.data;
        const groupedExpenses = expData.reduce((acc, expense) => {
          const { tourId,tourName,  driverName, customerName, tourCompletionDate, startLocation, endLocation, pax, tripStartDate, tourNameList, status, amount } = expense;
      
          if (!acc[tourId]) {
              acc[tourId] = {
                  tourId,
                  tourName,
                  driverName,
                  customerName,
                  tourCompletionDate,
                  startLocation,
                  endLocation,
                  pax,
                  tripStartDate,
                  tourNameList,
                  totalApproved: 0,
                  totalRejected: 0,
                  expenses: [] // Initialize an array for expenses
              };
          }
      
          // Convert amount to a number and add to totals
          if (status === "approved") {
              acc[tourId].totalApproved += parseFloat(amount);
          } else if (status === "rejected") {
              acc[tourId].totalRejected += parseFloat(amount);
          }
      
          acc[tourId].expenses.push({
              expenseId: expense.expenseId,
              expenseType: expense.expenseType,
              amount,
              description: expense.description,
              createdAt: expense.createdAt,
              status
          });
      
          return acc;
      }, {});
      
      // Convert the grouped object into an array
      const result = Object.values(groupedExpenses);
      
      setExpenses(result);
      } catch (error) {
      }
    };

    fetchExpenses();
  }, [data]);

  const showAllNotifications = (tourId, bookingId) => {
    history.push(`/trip-details/${tourId}/${bookingId}`);
  };

  return (
    <>
            {expenses && expenses.length > 0 ? (
        expenses.map((expense) => (
          expense.totalApproved > 0 && (  // Check if totalApproved is greater than 0
            <Card className='hoverCard' onClick={() => showAllNotifications(expense.tourId, expense.tourName)}>
              <Card.Body className='d-flex align-items-center justify-content-between'>
                <div>
                  <h6>{ShortenAddress(expense.endLocation)}</h6>
                  <p className='mb-0 f-14'>{moment(expense.tripStartDate).format('MMM DD')} {moment(expense.tripStartDate, 'HH:mm:ss').format('hh:mm A')}</p>
                  <p className='mb-0 f-14'>RM {expense.totalApproved}</p>
                </div>
                <div className={`icon-container icon icon-shape icon-shape-custom-width icon-md icon-shape-primary rounded me-sm-0`}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </Card.Body>
            </Card>
          )
      ))
  ) : (
        <Card>
          <Card.Body>
            No expenses found
          </Card.Body>
        </Card>  )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ToggleSwitch = ({ driverId }) => {
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const fetchDriverStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/freelancer/drivers/${driverId}/status`);
        console.log(response)
        const { driverStatus } = response.data;
        setIsOnline(driverStatus === 'available');
      } catch (error) {
        console.error('Error fetching driver status:', error);
      }
    };

    fetchDriverStatus();
  }, [driverId]);

  const handleToggle = async () => {
    const newStatus = isOnline ? 'inactive' : 'available';  // Toggle between 'available' and 'inactive'

    try {
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/freelancer/drivers/${driverId}/status`,
        { driverStatus: newStatus }
      );
      console.log('Status updated successfully', response.data);
      setIsOnline(!isOnline);  // Update the toggle state
    } catch (error) {
      console.error('Error updating driver status:', error);
    }
  };

  return (
    <div className="switch-container">
      <span>{isOnline ? 'Online' : 'Offline'}</span>
      <label className="switch">
        <input type="checkbox" checked={isOnline} onChange={handleToggle} />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import WeekViewCalendar from '../../../../components/WeekViewCalendar';

export default ({ data }) => {
  const history = useHistory();
  return (
    <>
      <div className="mb-4">
          <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faArrowLeft} size="md me-2" /> <h5 className="d-inline-block">Daily Earnings</h5>
          </p>
      </div>
      <WeekViewCalendar data={data} />
    </>
  );
};

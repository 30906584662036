const ShortenAddress = (address) => {
    // Split the address into parts
    const parts = address.split(',').map(part => part.trim());
    
    // Extract key components
    const name = parts[0]; // e.g., "New Dog Hamburger"
    const city = parts[parts.length - 2]; // e.g., "Itaim Bibi"
    const state = parts[parts.length - 1].split(' ')[0]; // e.g., "SP"
    
    // Return the shortened address
    return `${name}, ${city}, ${state}`;
}

export default ShortenAddress;
import React, { useEffect, useState } from 'react';
import { Card } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { CounterWidget } from '../../../components/Widgets';
import { RotatingLines } from 'react-loader-spinner';

const TourList = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchTours = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchUpcomingAssignedTours?driverId=${data.id}&page=${currentPage}`);
      setTours(response.data.data);
    } catch (error) {
    }
    finally {
      setLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchTours();
  }, [fetchTours]);

  if (loading) {
    return <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
      <RotatingLines
        visible={true}
        height="96"
        width="96"

        strokeWidth="5"
        strokeColor='#262B40'
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>;
  }

  return (
    <div>
      {tours.length === 0 ? (
        <Card><Card.Body>No trips found</Card.Body></Card>
      ) : (
        tours.map((tour) => (
          <CounterWidget
            additionalClass="mb-4"
            bookingId={tour.tourId}
            tourId={tour.tourName}
            title={tour.tourListName}
            pickUpDate={tour.tourDate}
            pickUpTime={tour.tourTime}
            pickUpPoint={tour.startLocation}
            dropPoint={tour.endLocation}
            fare={tour.fare}
            pax={tour.pax}
            iconColor="shape-secondary"
            mapUrl={tour.mapUrl}
          />
        ))
      )}
    </div>
  );
};

export default TourList;

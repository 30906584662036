
import React from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faHouseUser, faMapMarkedAlt, faCalendarCheck, faRobot } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";

export default ({ data }) => {
    const location = useLocation();
    const { pathname } = location;
    const NavItem = (props) => {
        const { className, title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? { href: link } : { as: Link, to: link };

        return (
            <Nav.Item className={`${navItemClassName} ${className}`}>
                <Nav.Link {...linkProps} target={target} className={classNames}>
                    <span>
                        {icon ? <span className="sidebar-icon">{icon}</span> : null}
                        {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="light" className="d-md-none py-0 shadow-sm-custom" id="footer" fixed="bottom">
                <Nav style={{ flexDirection: 'row' }}>
                    <NavItem link={Routes.DashboardOverview.path} icon={<FontAwesomeIcon icon={faHouseUser} />} title="Home" />
                    <NavItem className="text-center" icon={<FontAwesomeIcon icon={faMapMarkedAlt} />} link={Routes.UpcomingTours.path} title="Tours" />
                    { data.driverType === "freelancer" 
                        ? <NavItem className="text-center" icon={<FontAwesomeIcon icon={faRobot} />} link={Routes.AIChat.path} title="Trip AI" />
                        : <NavItem className="text-center" icon={<FontAwesomeIcon icon={faCalendarCheck} />} link={Routes.LeavePlan.path} title="Schedule" />
                    }
                    <NavItem className="text-center" icon={<FontAwesomeIcon icon={faWallet} />} link={Routes.Wallet.path} title="Wallet" />
                </Nav>
            </Navbar>

        </>
    );
};

import React, { useState, useEffect } from 'react';
import { Card } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faStar } from '@fortawesome/free-solid-svg-icons';
import FormatTz from '../../components/Utility/FormatTz';
import FormatTimeTz from '../../components/Utility/FormatTimeTz';

export default ({data, limit}) => {
  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/transactions?driverId=${data.id}&page=1&limit=${limit}`);
        const transactionsData = response.data.data;
        setTransactions(transactionsData);

      } catch (error) {
        console.error('Error fetching tours:', error);
      }
    };

    fetchTours();
  }, [data]);

  return (
    <>
      {transactions.length === 0 ? (
        <Card>
          <Card.Body>
            No transactions found
          </Card.Body>
        </Card>
      ) : (transactions && transactions.map((item) => {
        const icon = item.transactionType === 'credit'
          ? faArrowDown
          : item.transactionType === 'reward'
            ? faStar
            : faArrowUp;
        return (
          <>
            <Card className='mb-2'>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className={` ${item.transactionType === 'credit' ? 'alert-success' : item.transactionType === "reward" ? 'alert-warning' : 'alert-danger'} icon icon-shape icon-sm  rounded `}>
                      <FontAwesomeIcon icon={icon} className={item.transactionType === "reward" ? "" : 'rotate-rt-45'} />
                    </div>
                    <div className='mx-3 mr-0'>
                      <p className="paragraph-section__description mb-0-imp f-12 fw-bold">
                        {item.description}
                      </p>
                      <span className='small text-gray fw-regular f-12'>
                        {FormatTz(item.transactionDate)} | {FormatTimeTz(item.transactionDate)}
                      </span>
                    </div>
                  </div>
                  <p className={` f-14 progress-label fw-bold mb-0 ${item.transactionType === 'credit' ? 'text-tertiary' : item.transactionType === "reward" ? 'text-warning' : 'text-danger'}`}>
                    <span className='px-1'>{item.transactionType === 'credit' || item.transactionType === 'reward' ? '+' : '-'}</span>
                    <span class="f-10 d-iblock vta-m">RM</span> {item.amount}
                  </p>
                </div>
              </Card.Body>
            </Card>
          </>
          )}))}
      </>
  );
};

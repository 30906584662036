import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Container, InputGroup, Button, Spinner, Image } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { Routes } from '../../routes';

import TvgLogo from '../../assets/img/brand/TV-Logo.png'

export class Signin extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      userRole: 'driver',
      loading: false,
      incorrectCredentials: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleInputChange(e) {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
      incorrectCredentials: false // Reset incorrect credentials state on input change
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { email, password, userRole } = this.state;

    this.setState({ loading: true });

    axios
    .post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/login`, { email, password, userRole })
        .then(res => {
        if (res.status === 200) {
          const { data: { token, userRole } } = res;
          localStorage.setItem('token', token);
          localStorage.setItem('userRole', userRole);

          if (userRole === 'admin') {
            this.props.history.push('/');
          } else if (userRole === 'driver') {
            this.props.history.push('/');
          } else {
            throw new Error('Unknown user role');
          }
        } else {
          throw new Error('Invalid status code');
        }
      })
      .catch(err => {
        console.error('Login error:', err);
        this.setState({ incorrectCredentials: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { email, password, loading, incorrectCredentials } = this.state;

    return (
      <main>
        <section className="login-page bg-white d-flex align-items-center justify-content-center">
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center mb-2">
                    <Image src={TvgLogo} className="w-48Percent" />
                  </div>
                  <div className="text-center text-md-center mb-2 mt-md-0">
                    <h4 className="mb-0">Sign in to our platform</h4>
                  </div>
                  <Form className="mt-2" onSubmit={this.onSubmit}>
                    <Form.Group id="email" className="mb-2">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          name="email"
                          autoFocus
                          required
                          type="email"
                          placeholder="Enter email address"
                          value={email}
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          name="password"
                          required
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    {incorrectCredentials && (
                      <p className="my-2 mt-4 text-danger">
                        Incorrect email or password. Please try again.
                      </p>
                    )}
                    <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner animation="border" size="sm" className="me-2" />
                          Loading...
                        </>
                      ) : (
                        'Sign in'
                      )}
                    </Button>
                  </Form>
                  <div className="mt-4 text-center f-14">
                  <div> 
                    <span className="fw-normal">
                      Don't have an account yet?{' '}
                    </span>
                  </div>
                  <div>
                    <span className="fw-normal">
                      <Link to={Routes.Signup.path} className='text-decoration-none'> Create account</Link>
                    </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
        </section>
      </main>
    );
  }
}

export default Signin;

import React, { useState } from 'react';
import { Card, Badge, Button } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteLeavePlan from './DeleteLeavePlan';
import axios from 'axios';

const LeaveCard = ({ leave, category, onSuccess, onError }) => {
    const [showDeleteLeavePlan, setShowDeleteLeavePlan] = useState(false);
    const [loading, setLoading] = useState(false);

    const getBadgeColor = () => {
        switch (leave.leaveStatus) {
            case 'approved':
                return 'success';
            case 'pending':
                return 'warning';
            case 'rejected':
                return 'danger';
            default:
                return 'secondary';
        }
    };

    const handleDelete = () => {
        setShowDeleteLeavePlan(true);
    };

    const handleClose = () => {
        setShowDeleteLeavePlan(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await axios.delete(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/${leave.driverId}/${leave.id}/cancelLeavePlan`
            );
            onSuccess('Leave request deleted successfully.');
        } catch (error) {
            onError(error.response ? error.response.data.message : error.message);
        } finally {
            setLoading(false);
            setShowDeleteLeavePlan(false);
        }
    };

    const handleConfirm = (e) => {
        handleSubmit(e);
    };

    return (
        <>
            <Card className='mb-2'>
                <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='text-capitalize'>{leave.leaveType}</h5>
                            <span className='f-14'>
                                {moment(leave.leaveStartDate).format('Do MMM')} - {moment(leave.leaveEndDate).format('Do MMM, YYYY')}
                            </span>
                            {leave.remarks !== null && <p className='mt-1 mb-0'>Remarks: {leave.remarks}</p>}
                        </div>
                        <div>
                            <Badge bg={getBadgeColor()} variant="primary" size="sm" className="badge-lg px-2 upperCase-keyword fw-regular f-12">
                                {leave.leaveStatus}
                            </Badge>
                            {category === "upcoming" && (
                                <Button variant="primary" size="sm" className='animate-up-2 f-12 mx-2' onClick={handleDelete}>
                                    <FontAwesomeIcon className="f-10" icon={faTrash} />
                                </Button>
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card>

            {showDeleteLeavePlan && (
                <DeleteLeavePlan
                    showDefault={showDeleteLeavePlan}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                />
            )}

            {loading && <div>Loading...</div>}
        </>
    );
};

export default LeaveCard;

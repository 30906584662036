import React from 'react';
import { Card, Badge, Button } from '@themesberg/react-bootstrap';
import moment from 'moment';

export default ({ expenses }) => {
  // Grouping expenses by tourId
  const groupedExpenses = expenses.reduce((acc, expense) => {
    const { tourId } = expense;
    if (!acc[tourId]) {
      acc[tourId] = [];
    }
    acc[tourId].push(expense);
    return acc;
  }, {});

  return (
    <div>
      {Object.keys(groupedExpenses).map((tourId) => {
        const tourExpenses = groupedExpenses[tourId];

        // Get common details for the tour
        const firstExpense = tourExpenses[0];
        const tourDetails = {
          tourName: firstExpense.tourName,
          customerName: firstExpense.customerName,
          tripStartDate: firstExpense.tripStartDate,
          tourCompletionDate: firstExpense.tourCompletionDate,
          startLocation: firstExpense.startLocation,
          endLocation: firstExpense.endLocation,
        };

        return (
          <Card className="mb-4" key={tourId}>
            <Card.Body>
              <div>
                {/* Common tour details */}
                <h3 className="text-capitalize">{tourDetails.endLocation}</h3>
                <p className="mb-1">Trip ended on {moment(tourDetails.tourCompletionDate).format('Do MMM, YYYY')}</p>
              </div>

              <div className="mt-3">
                {/* Approved Expenses */}
                <h6>Approved Expenses</h6>
                {tourExpenses.filter(exp => exp.status === 'approved').length > 0 ? (
                  tourExpenses.filter(exp => exp.status === 'approved').map((expense, index, array) => (
                    <div key={expense.expenseId} className={`mb-3 ${array.length > 1 && index < array.length - 1 ? 'border-bottom pb-3' : ''}`}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="mb-1"><strong>Expense Type:</strong> {expense.expenseType}</p>
                          <p className="mb-1"><strong>Amount:</strong> RM {expense.amount}</p>
                        </div>
                        <div>
                          <Badge bg="success" className="badge-lg px-2">Approved</Badge>
                        </div>
                      </div>
                      <p className="mb-0"><strong>Description:</strong> {expense.description || 'N/A'}</p>
                    </div>
                  ))
                ) : (
                  <p>No approved expenses.</p>
                )}
              </div>

              <div className="mt-3">
                {/* Rejected Expenses */}
                <h6>Rejected Expenses</h6>
                {tourExpenses.filter(exp => exp.status === 'rejected').length > 0 ? (
                  tourExpenses.filter(exp => exp.status === 'rejected').map((expense, index, array) => (
                    <div key={expense.expenseId} className={`mb-3 ${array.length > 1 && index < array.length - 1 ? 'border-bottom pb-3' : ''}`}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="mb-1"><strong>Expense Type:</strong> {expense.expenseType}</p>
                          <p className="mb-1"><strong>Amount:</strong> RM {expense.amount}</p>
                        </div>
                        <div>
                          <Badge bg="danger" className="badge-lg px-2">Rejected</Badge>
                        </div>
                      </div>
                      <p className="mb-1"><strong>Reason:</strong> {expense.reason || 'N/A'}</p>
                      <p className="mb-1"><strong>Admin Comment:</strong> {expense.adminComment || 'N/A'}</p>
                    </div>
                  ))
                ) : (
                  <p>No rejected expenses.</p>
                )}
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
};

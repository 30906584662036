import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ViolationsList from './ViolationsList';
export default ({ data }) => {
  const history = useHistory();
  return (
    <>
      <div className="mb-4">
          <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faArrowLeft} size="md me-2" /> <h5 className="d-inline-block">Violations</h5>
          </p>
      </div>
      <ViolationsList data={data} />
    </>
    )
}
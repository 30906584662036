import React from 'react';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import Expenses from '../subComponents/Expenses';

export default ({ data }) => {
    return (
        <Row className='mb-4'>
            <Col xs={12} md={12}>
                <Card className="bg-transparent mb-3 border-0 hover">
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 className="mb-3">
                            <FontAwesomeIcon icon={faMoneyCheckAlt} className="me-2" /> Expenses
                        </h2>
                        {/* <Link to="/transactions">
                            <Button variant="outline-primary" size="sm" className="m-1 upperCase-keyword">
                                <span className='space-after'>See All </span> <FontAwesomeIcon icon={faAngleRight} />
                            </Button>
                        </Link> */}
                    </div>
                </Card>
                {Expenses(data)}
            </Col>
        </Row>
    );
};


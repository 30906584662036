import React from 'react';
import { Card, Breadcrumb, Row, Col } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCreditCard, faHome, faMedal, faMoneyBill, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import TransactionCard from './subComponents/TransactionCard';
import FilterSection from './subComponents/FilterSection';
import SortSection from './subComponents/SortSection';
import { useHistory } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';

export default ({ data }) => {
    const history = useHistory();
    const [transactionCounts, setTransactionCounts] = React.useState({
        total: 0,
        withdrawal: 0,
        rewards: 0,
        currentBalance: 0,
        withdrawalBalance: 0,
        rewardBalance: 0
    });

    const handleTransactionData = (counts) => {
        setTransactionCounts(counts);
    };

    return (
        <>
            <div>
                <div className="mb-4">
                    <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faArrowLeft} size="md me-2" /> <h5 className="d-inline-block">Transactions</h5>
                    </p>
                </div>
                {/* <Row>
                    <Col xs={12} sm={6} xl={3} className="mb-2">
                        <Card>
                            <Card.Body className='d-flex justify-content-between align-items-center'>
                                <div className='alert-danger icon icon-shape icon-sm  rounded' icon icon-shape icon-sm rounded>
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                </div>
                                <div>
                                    <p className="mb-1 text-muted">Current Balance</p>
                                    <h4 className="mb-0">RM {transactionCounts.currentBalance}</h4>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} xl={3} className="mb-2">
                        <Card>
                            <Card.Body className='d-flex justify-content-between align-items-center'>
                                <div className='alert-warning icon icon-shape icon-sm  rounded' icon icon-shape icon-sm rounded>
                                    <FontAwesomeIcon icon={faMedal}/>
                                </div>
                                <div>
                                    <p className="mb-1 text-muted">Reward Balance</p>
                                    <h4 className="mb-0">RM {transactionCounts.rewardBalance}</h4>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} xl={3} className="mb-2">
                        <Card>
                            <Card.Body className='d-flex justify-content-between align-items-center'>
                                <div className='alert-danger icon icon-shape icon-sm  rounded' icon icon-shape icon-sm rounded>
                                    <FontAwesomeIcon icon={faCreditCard} />
                                </div>
                                <div>
                                    <p className="mb-1 text-muted">Withdrawal Amount</p>
                                    <h4 className="mb-0">RM {transactionCounts.withdrawalBalance}</h4>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} xl={3} className="mb-2">
                        <Card>
                            <Card.Body className='d-flex justify-content-between align-items-center'>
                                <div className='alert-tertiary icon icon-shape icon-sm  rounded' icon icon-shape icon-sm rounded>
                                    <FontAwesomeIcon icon={faPlusSquare} />
                                </div>
                                <div>
                                    <p className="mb-1 text-muted">Expenses Amount</p>
                                    <h4 className="mb-0">RM {transactionCounts.withdrawalBalance}</h4>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}
            </div>
            <div className="my-4">
                <h5>All Transactions</h5>
            </div>
            < TransactionCard data={data} limit={10} handleTransactionData={handleTransactionData} />
        </>
    );
};

import React, { useState, useEffect, useCallback } from 'react';
import { Spinner, Alert } from '@themesberg/react-bootstrap';
import axios from 'axios';
import VehicleCard from './VehicleCard';
import { RotatingLines } from 'react-loader-spinner';

export default ({ data }) => {
    const [vehicles, setVehicles] = useState({ current: [], past: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchVehicles = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/${data.id}/vehicles`
            );

            const categorizedVehicles = { current: [], past: [] };

            response.data.vehicleHistory.forEach(vehicle => {
                categorizedVehicles.past.push(vehicle);
            });

            // Add the current vehicle (if available) as "Upcoming"
            if (response.data.currentVehicle) {
                categorizedVehicles.current.push(response.data.currentVehicle);
            }

            setVehicles(categorizedVehicles);

        } catch (error) {
            setError('Error fetching leave data');
        } finally {
            setLoading(false);
        }
    }, [data.id]);

    useEffect(() => {
        fetchVehicles();
    }, [data.id]);

    const handleSuccess = (message) => {
        setError(null);
        fetchVehicles(); // Reload the leave data after success
    };

    const handleError = (message) => {
        setError(message);
    };

    if (loading) {
        return <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
            <RotatingLines
                visible={true}
                height="96"
                width="96"

                strokeWidth="5"
                strokeColor='#262B40'
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <>
            <div className="my-4">
                <h5>Current Vehicle</h5>
                {vehicles.current.length === 0 ? (
                    <Alert variant="info">No Vehicle Assigned</Alert>
                ) : (
                    vehicles.current.map((vehicle) => (
                        <VehicleCard
                            vehicle={{ ...vehicle, driverId: data.id }}
                            category="current"
                            background="bg-D1E5E6"
                        />
                    ))
                )}
            </div>

            <div className="mb-4">
                <h5>Vehicles History</h5>
                {vehicles.past.length === 0 ? (
                    <Alert variant="info">No Vehicle History</Alert>
                ) : (
                    vehicles.past.map((vehicle) => (
                        <VehicleCard
                            vehicle={{ ...vehicle, driverId: data.id }}
                            category="past"
                        />
                    ))
                )}
            </div>
        </>
    );
};

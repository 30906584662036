import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';
import { Routes } from '../../../routes';
import { Link } from 'react-router-dom';

export default ({ data }) => {
    const [walletData, setWalletData] = useState();
    const [loading, setLoading] = useState();

    const fetchEarnings = async (date) => {
      setLoading(true);
      try {
        const malaysiaTime = moment.tz(date, 'Asia/Kuala_Lumpur'); // Convert to Malaysia time
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/${data.id}/dailyEarnings`, {
          startDate: malaysiaTime.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          endDate: malaysiaTime.clone().add(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        });
        setWalletData(response.data);
      } catch (error) {
        console.error("Error fetching earnings:", error);
      }
      setLoading(false);
    };
    
    useEffect(() => {
      const todayInMalaysia = moment().tz('Asia/Kuala_Lumpur');
      fetchEarnings(todayInMalaysia);
    }, []);
        
    function removeDecimal(amount) {
        return amount.replace(/(\.00|\s\.00)$/g, '');
    }

    return (
        <Row>
            <Col xs={12} md={12}>
                {/* Wallet Details Card */}
                <Card border="light" className="bg-white">
                    {walletData && (
                        <>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className='text-center mb-3'>
                                            <span className="mb-1 text-muted">Today</span>
                                            <h2 className="mb-0 fw-bolder f-48">RM {removeDecimal(parseInt(walletData.totalAmount).toFixed(2))}</h2>
                                        </div>
                                        <Link to={Routes.Earnings.path}>
                                            <div className='border-top-f4f2f2 pt-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <h6 className="mb-0 f-16">
                                                        Track Daily Earning
                                                    </h6>
                                                    <FontAwesomeIcon className="mx-2" size="sm" icon={faAngleRight} />
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </>
                    )}
                </Card>
            </Col>
        </Row>
    );
};

import React from 'react';
import { Badge } from '@themesberg/react-bootstrap';

export default ({ tripData }) => {
  return (
      <Badge 
        bg={getBadgeColor(tripData.status)} 
        variant="primary" 
        size="sm" 
        className="card-subtitle badge-md px-2 upperCase-keyword fw-regular f-12"
      >
        {getStatusText(tripData.status)}
      </Badge>
  );
};

function getBadgeColor(status) {
  switch (status) {
    case 'pending':
      return 'warning';       // Yellow for pending
    case 'confirmed':
      return 'info';          // Blue for confirmed
    case 'inProgress':
      return 'primary';       // Blue for in progress
    case 'completed':
      return 'success';       // Green for completed
    case 'cancelled':
      return 'danger';        // Red for cancelled
    case 'expired':
      return 'secondary';     // Grey for expired
    case 'acceptedByDriver':
      return 'success';       // Green for accepted by driver
    case 'rejectedByDriver':
      return 'danger';        // Red for rejected by driver
    case 'driverAssigned':
      return 'info';          // Blue for driver assigned
    case 'driverUnassigned':
      return 'warning';       // Yellow for driver unassigned
    default:
      return 'light';         // Default to light if status is undefined
  }
}

function getStatusText(status) {
  switch (status) {
    case 'pending':
      return 'Yet to Start';          // Status text for 'pending'
    case 'confirmed':
      return 'Confirmed';            // Status text for 'confirmed'
    case 'inProgress':
      return 'In Progress';          // Status text for 'inProgress'
    case 'completed':
      return 'Completed';            // Status text for 'completed'
    case 'cancelled':
      return 'Cancelled';            // Status text for 'cancelled'
    case 'expired':
      return 'Expired';              // Status text for 'expired'
    case 'acceptedByDriver':
      return 'Accepted by Driver';   // Status text for 'acceptedByDriver'
    case 'rejectedByDriver':
      return 'Rejected by Driver';   // Status text for 'rejectedByDriver'
    case 'driverAssigned':
      return 'Driver Assigned';      // Status text for 'driverAssigned'
    case 'driverUnassigned':
      return 'Driver Unassigned';    // Status text for 'driverUnassigned'
    default:
      return 'Unknown';              // Default text if status is undefined
  }
}
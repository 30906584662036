import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClock, faLocationArrow, faMapMarkedAlt, faPhoneAlt, faTag, faUser, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '@themesberg/react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import ShowRating from "../../components/Utility/ShowRating";
import FormatTz from "../../components/Utility/FormatTz";
import TripDetailsFormatTz from "../../components/Utility/TripDetailsFormatTz";
import FormatTimeTz from "../../components/Utility/FormatTimeTz";
import ShortenAddress from "../../components/Utility/ShortenAddress";
import TourItinerary from "../../components/Utility/TourItinerary";
import MapWidget from "../../components/Utility/MapWidget";

const TripDetails = () => {
    const [tripData, setTripData] = useState(null);
    const { bookingId, id } = useParams();
    const history = useHistory(); // Access to the history object

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tripDetailsBrief?tourId=${bookingId}&bookingId=${bookingId}`);
                setTripData(response.data); // Assuming response.data contains trip details

            } catch (error) {
            }
        };

        fetchUserData();
    }, [id, bookingId]);

    function getBadgeColor(status) {
        switch (status) {
            case 'pending':
                return 'warning';       // Yellow for pending
            case 'confirmed':
                return 'info';          // Blue for confirmed
            case 'inProgress':
                return 'primary';       // Blue for in progress
            case 'completed':
                return 'success';       // Green for completed
            case 'cancelled':
                return 'danger';        // Red for cancelled
            case 'expired':
                return 'secondary';     // Grey for expired
            case 'acceptedByDriver':
                return 'success';       // Green for accepted by driver
            case 'rejectedByDriver':
                return 'danger';        // Red for rejected by driver
            case 'driverAssigned':
                return 'info';          // Blue for driver assigned
            case 'driverUnassigned':
                return 'warning';       // Yellow for driver unassigned
            default:
                return 'light';         // Default to light if status is undefined
        }
    }

    function getStatusText(status) {
        switch (status) {
            case 'pending':
                return 'Yet to Start';          // Status text for 'pending'
            case 'confirmed':
                return 'Confirmed';            // Status text for 'confirmed'
            case 'inProgress':
                return 'In Progress';          // Status text for 'inProgress'
            case 'completed':
                return 'Completed';            // Status text for 'completed'
            case 'cancelled':
                return 'Cancelled';            // Status text for 'cancelled'
            case 'expired':
                return 'Expired';              // Status text for 'expired'
            case 'acceptedByDriver':
                return 'Upcoming';   // Status text for 'acceptedByDriver'
            case 'rejectedByDriver':
                return 'Rejected';   // Status text for 'rejectedByDriver'
            default:
                return 'Unknown';              // Default text if status is undefined
        }
    }

    return (
        <>
            {tripData && (
                <>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 pt-0">
                        <div className="d-block mb-md-0">
                            <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faArrowLeft} size="md me-2" /> <h5 className="d-inline-block"> Trip Details</h5>
                            </p>
                        </div>
                    </div>
                </>
            )}
            <h2 className="mb-4">Your trip</h2>
            {tripData &&
                (<>
                
                <h4 className="f-18 fw-normal mb-4">{tripData.tourname}</h4>
                <h4 className="f-18 fw-normal mb-4">{TripDetailsFormatTz(tripData.tourDate, tripData.tourTime)} with <span className="text-capitalize">{tripData.customerName}</span></h4>
                    <div className="mb-4">
                        <Badge
                            bg={getBadgeColor(tripData.status)}
                            variant="primary"
                            size="sm"
                            className="card-subtitle badge-md px-2 upperCase-keyword fw-regular"
                        >
                            {getStatusText(tripData.status)}
                        </Badge>
                    </div>
                    <div className="mb-4 ">
                        <MapWidget mapString={tripData.mapUrl} />
                    </div>
                    {tripData.status === 'completed' && <div className="mb-4 pb-4 border-bottom">
                        <h6 className="f-20 mb-3">Rating</h6>
                        <div className="d-flex justify-content-start mt-2">
                            <ShowRating rating={tripData.ratingScore} />
                        </div>
                    </div>}
                    <div className="mb-4 pb-4 border-bottom">
                        <h6 className="f-20 mb-3">Trip Details</h6>
                        {tripData.status === 'completed' && <div className="d-flex justify-content-start align-items-center mt-3">
                            <FontAwesomeIcon icon={faClock} />
                            <div className="px-3">
                                <p className="mb-0 f-16">
                                    {FormatTz(tripData.completedAt)} {FormatTimeTz(tripData.completedAt)}
                                </p>
                            </div>
                        </div>}
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <FontAwesomeIcon icon={faTag} />
                            <div className="px-3">
                                <p className="mb-0 f-16 text-capitalize fw-semibold">
                                    RM {tripData.fare}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <FontAwesomeIcon icon={faWallet} />
                            <div className="px-3">
                                <p className="mb-0 f-16 text-capitalize">
                                    {tripData.paymentStatus}
                                </p>
                            </div>
                        </div>
                    </div>
                    {tripData.status !== 'completed' && <div className="mb-4 pb-4 border-bottom">
                        <h6 className="f-20 mb-3">Guest Details</h6>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <FontAwesomeIcon icon={faUser} />
                            <div className="px-3">
                                <p className="mb-0 f-16 fw-semibold text-capitalize">
                                    {tripData.customerName}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <FontAwesomeIcon icon={faPhoneAlt} />
                            <div className="px-3">
                                <p className="mb-0 f-16 fw-semibold text-capitalize">
                                    {tripData.phone}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <FontAwesomeIcon icon={faUsers} />
                            <div className="px-3">
                                <p className="mb-0 f-16 fw-semibold text-capitalize">
                                    Pax: {tripData.pax}
                                </p>
                            </div>
                        </div>
                    </div>
}
                    <div className="mb-4 pb-4 border-bottom">
                        <h6 className="f-20 mb-3">Route</h6>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <FontAwesomeIcon icon={faLocationArrow} />
                            <div className="px-3">
                                <p className="mb-0 f-16 fw-semibold text-capitalize">
                                    {ShortenAddress(tripData.startLocation)}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                            <div className="px-3">
                                <p className="mb-0 f-16 fw-semibold text-capitalize">
                                    {ShortenAddress(tripData.endLocation)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 pb-4 border-bottom">
                        <h6 className="f-20 mb-3">Itinerary</h6>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <TourItinerary itinerary={tripData.itinerary} />
                        </div>
                    </div>
                    <div className="mb-4 pb-4 border-bottom">
                        <h6 className="f-20 mb-3">Inclusions</h6>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <TourItinerary itinerary={tripData.inclusions} />
                        </div>
                    </div>
                    <div className="mb-4 pb-4">
                        <h6 className="f-20 mb-3">Remarks</h6>
                        <div className="d-flex justify-content-start align-items-center mt-3">
                            <p className="f-16 pb-2">{tripData.remarks} </p>
                        </div>
                    </div>
                </>)}
        </>
    );
};

export default TripDetails;

export default (driverId) => {
    // Replace `YOUR_WEBSOCKET_URL` with your WebSocket API endpoint
    const url = `wss://7l9sc71fx9.execute-api.ap-southeast-1.amazonaws.com/production/`;
    console.log(url);
    const socket = new WebSocket(url);

    socket.onopen = () => {
        console.log('WebSocket connection established');
        // Send an initial message
        socket.send(JSON.stringify({ action: 'liveLocation', message: JSON.stringify({ driverId, message: 'new trip assigned' }) }));
        
        // Start sending location every 3 seconds
        setInterval(() => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const locationData = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };
                    socket.send(JSON.stringify({ action: 'updateLocation', driverId, location: locationData }));
                });
            }
        }, 3000);
    };

    socket.onmessage = (event) => {
        console.log("onMessage", event);
    };

    socket.onclose = () => {
        console.log('WebSocket connection closed');
    };

    socket.onerror = (error) => {
        console.error('WebSocket error:', error);
    };

    // Cleanup on component unmount
    return () => {
        if (socket) {
            socket.close();
        }
    };
};
